<template>
    <div>
        <template v-if="blok.product_links && blok.product_links.length > 0">
            <template v-for="marker in blok.product_links"
                :key="marker.id">
                <sb-product-image-lift-marker v-if="marker.product?.source"
                    :marker="marker"
                    :products="products"
                    class="absolute left-[var(--marker-position-x)] top-[var(--marker-position-y)]"
                    :style="{
                        '--marker-position-x': `${marker.position_x}%`,
                        '--marker-position-y': `${marker.position_y}%`
                    }" />
            </template>
        </template>

        <template v-if="blok.image">
            <nuxt-link v-if="blok.link_to_room.cached_url.length > 0"
                :to="formatSbUrl(blok.link_to_room.cached_url)"
                :aria-label="blok.title"
                @click="addPromotionClick(promo)">
                <nuxt-picture :src="blok.image.filename"
                    class="aspect-[3/2]"
                    width="760"
                    height="507"
                    provider="storyblok"
                    fit="in"
                    loading="lazy"
                    sizes="sm:100vw md:768px lg:504px xl:632px 2xl:760px"
                    :img-attrs="{
                        class: 'object-cover w-full h-full'
                    }"
                    :alt="blok.image.alt ?? blok.title" />
            </nuxt-link>

            <nuxt-picture v-else
                :src="blok.image.filename"
                class="aspect-[3/2]"
                width="760"
                height="507"
                provider="storyblok"
                fit="in"
                loading="lazy"
                sizes="sm:100vw md:768px lg:504px xl:632px 2xl:760px"
                :img-attrs="{
                    class: 'object-cover w-full h-full'
                }"
                :alt="blok.image.alt ?? blok.title" />
        </template>
    </div>
</template>

<script setup lang="ts">
import type { BlokComponent } from '~~/types';
import type {
    ViewMarkerProductsQuery,
} from '~~/graphql';

const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-product-image-lift' }>
    id?: string | number
    name?: string | null
    creative?: string | null
    page?: string
    type?: string
    products: ViewMarkerProductsQuery['products']['edges'][0]['node'][]
}>();

const route = useRoute();
const { format: formatSbUrl } = useSbUrl();
const { addPromotionClick } = usePromotion();

function convertString(string: string) {
    const words = string.split('-');
    const convertedString = words.map(((word) => word.charAt(0).toUpperCase() + word.slice(1))).join(' ');
    return convertedString;
}

const promo = computed(() => ({
    /* eslint-disable @typescript-eslint/naming-convention */
    creative_name: convertString(properties.blok.component),
    creative_slot: properties.blok.component, // component name
    // eslint-disable-next-line no-underscore-dangle
    promotion_id: properties.blok._uid,
    promotion_name: properties.blok.title,
    source_url: route.fullPath,
    target_url: properties.blok.link_to_room.cached_url,
    /* eslint-enable @typescript-eslint/naming-convention */
}));
</script>

<style scoped>

</style>
